import { IconProps } from './types'

export const Spade = ({ className }: IconProps) => {
  return (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.3932 2.2759C14.5699 2.09924 14.8095 2 15.0593 2C15.3092 2 15.5488 2.09924 15.7255 2.2759L17.0541 3.60456L17.0579 3.60833L17.0626 3.6121L18.3912 4.94077C18.5679 5.11759 18.6671 5.35735 18.667 5.60732C18.6669 5.85729 18.5675 6.09699 18.3907 6.27368C18.2139 6.45037 17.9742 6.54958 17.7242 6.5495C17.4742 6.54941 17.2346 6.45002 17.0579 6.27321L16.3917 5.60699L11.7275 10.2715L13.0598 11.6039C13.2349 11.7789 13.3738 11.9867 13.4686 12.2154C13.5633 12.4441 13.6121 12.6892 13.6121 12.9368C13.6121 13.1844 13.5633 13.4295 13.4686 13.6582C13.3738 13.8869 13.2349 14.0947 13.0598 14.2697L10.7098 16.6198C10.0911 17.2386 9.31234 17.6729 8.46074 17.874C7.60913 18.075 6.71836 18.0349 5.88826 17.7582L4.99405 17.4604C4.57772 17.3215 4.19945 17.0876 3.8892 16.7771C3.57894 16.4667 3.34523 16.0883 3.20657 15.6719L2.90881 14.7786C2.63208 13.9484 2.59199 13.0576 2.79303 12.2059C2.99408 11.3543 3.42831 10.5755 4.04707 9.95672L6.39708 7.60564C6.57209 7.43056 6.77987 7.29167 7.00856 7.19691C7.23725 7.10215 7.48237 7.05338 7.72992 7.05338C7.97746 7.05338 8.22258 7.10215 8.45127 7.19691C8.67996 7.29167 8.88775 7.43056 9.06275 7.60564L10.3951 8.93902L15.0593 4.27455L14.3932 3.60833C14.2165 3.43162 14.1173 3.19198 14.1173 2.94212C14.1173 2.69225 14.2165 2.45261 14.3932 2.2759Z'
        fill='currentColor'
      />
    </svg>
  )
}
