import { IconProps } from './types'

export const Seedling = ({ className }: IconProps) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18 3C18 6.55 15.3562 9.48438 11.9312 9.9375C11.7094 8.26875 10.975 6.7625 9.89062 5.58437C11.0875 3.44687 13.375 2 16 2H17C17.5531 2 18 2.44687 18 3ZM2 5C2 4.44688 2.44687 4 3 4H4C7.86562 4 11 7.13438 11 11V12V17C11 17.5531 10.5531 18 10 18C9.44688 18 9 17.5531 9 17V12C5.13438 12 2 8.86562 2 5Z'
        fill='currentColor'
      />
    </svg>
  )
}
