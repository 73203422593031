import { useCallback, useMemo } from 'react'

import { Button } from 'src/components/atoms/Button'
import { ButtonLinkProps } from 'src/components/atoms/Link'

import { CtaButton, InternalLink } from 'src/config/generated/sanitySchema'
import { useMessenger } from 'src/hooks/useMessenger'

import { CallToActionButtonLink } from '../CallToActionButtonLink'

export interface CmsCtaButtonProps
  extends Omit<CtaButton, '_type' | 'internalLink' | 'color'> {
  internalLink: InternalLink | undefined
  size?: ButtonLinkProps['size']
  variant?: ButtonLinkProps['variant']
  color?: ButtonLinkProps['color']
}

export const CmsCtaButton = ({
  text,
  externalUrl,
  internalLink,
  linkType,
  variant = 'contained',
  actionType,
  color = 'primary',
  size = 'base'
}: CmsCtaButtonProps) => {
  const { showNewMessage } = useMessenger()
  const href = useMemo(() => {
    if (linkType === 'internal') {
      return internalLink?.path
    }
    if (linkType === 'external') {
      return externalUrl
    }
    return null
  }, [externalUrl, internalLink?.path, linkType])

  const handleOnClick = useCallback(() => {
    if (linkType === 'action') {
      switch (actionType) {
        case 'messenger':
          showNewMessage()
          break
        default:
          break
      }
    }
  }, [actionType, linkType, showNewMessage])

  if (linkType === 'action') {
    return (
      <Button
        onClick={handleOnClick}
        color={color}
        size={size}
        variant={variant}
      >
        {text}
      </Button>
    )
  }

  return (
    <CallToActionButtonLink
      isExternal={linkType === 'external'}
      color={color}
      href={href || ''}
      size={size}
      variant={variant}
    >
      {text}
    </CallToActionButtonLink>
  )
}
